<div class="data">
  <br />
  <mat-form-field appearance="outline" class="search-form" *ngIf="loadData">
    <mat-icon matPrefix  style="color: #741b1b">search</mat-icon>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Global Search"
      [matAutocomplete]="auto"
       style="margin-left: 10px"
      [(ngModel)]="value"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        (click)="selectsearch(globalsearch)"
        style="color: #741b1b"
        *ngFor="let globalsearch of globalsearches"
        [value]="globalsearch"
        >{{ globalsearch }}</mat-option
      >
      <mat-option
        (click)="selectsearch(agenda_documents_result)"
        style="color: #000"
        *ngFor="let agenda_documents_result of agenda_documents_results"
        [value]="agenda_documents_result"
        >{{ agenda_documents_result.document_name }} --- Agenda docs</mat-option
      >
      <mat-option
        (click)="viewSearchMeetingDetails(meeting_result)"
        style="color: #000"
        *ngFor="let meeting_result of meeting_results"
        [value]="meeting_result"
        >{{ meeting_result.meeting_title }} --- Meeting</mat-option
      >
      <mat-option
        (click)="viewVotingDetail(voting_question)"
        style="color: #000"
        *ngFor="let voting_question of voting_questions"
        [value]="voting_question"
        >{{ voting_question.title }} --- voting questions</mat-option
      >
      <mat-option
        (click)="openSurveyObjView(survey)"
        style="color: #000"
        *ngFor="let survey of surveys"
        [value]="survey"
        >{{ survey.survey_title }} --- survey</mat-option
      >
      <mat-option
        (click)="openSignature(signature)"
        style="color: #000"
        *ngFor="let signature of signatures"
        [value]="signature"
        >{{ signature.signature_title }} --- esignature</mat-option
      >
      <mat-option
        (click)="openCompliance(compliance)"
        style="color: #000"
        *ngFor="let compliance of all_compliance"
        [value]="compliance.compliance_name"
        >{{ compliance.compliance_name }} --- compliance management</mat-option
      >
      <mat-option
        (click)="manageTaskDialog(task)"
        style="color: #000"
        *ngFor="let task of tasks"
        [value]="task.task_name"
        >{{ task.task_name }} --- tasks</mat-option
      >
    </mat-autocomplete>
    <button
      *ngIf="value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="value = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <br />
  <mat-card class="card">
    <div class="heading-section">
      <div class="heading desc-section">
        <mat-card-title>
          <h2
            style="
              color: #201547;
              font-weight: bold;
              font-family: Poppins, sans-serif !important;
              font-size: 25px;
              padding-left: 10px;
            "
          >
            {{ organizationsObj[0]?.name }}
          </h2>
        </mat-card-title>
        <div class="container">
          <div class="row row-cols-0">
            <div class="col-0 font:Poppins">
              <span style="font-size: 20px; font:Poppins font-weight: bold; color: #201547"
                >Description</span
              ><br /><br />
              {{ organizationsObj[0]?.description
              }}<span style="margin-right: 0px"></span>
            </div>
            <!--
            <div class="col-4">
              <div class="logo">
                <img
                  src="../../assets/img/coseke.png"
                  alt=""
                  class="img-responsive"
                  srcset=""
                />
              </div>
            </div> -->
          </div>
        </div>
        <br />
        <br />
      </div>

      <div *ngIf="currentUser?.org_permission == 'Admin'" class="meeting-edit">
        <button
          mat-button
          [disabled]="!(currentUser?.org_permission == 'Admin')"
          color="primary"
          (click)="openEditDetailsDialog()"
        >
          <span>Edit details</span>
        </button>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> More details </mat-panel-title>
        </mat-expansion-panel-header>

        <br />
        <mat-card class="example-card">
          <div class="heading">
            <mat-card-title>Mission</mat-card-title>
            <p>
              {{ organizationsObj[0]?.mission }}
            </p>
            <!-- <p>
              To provide leadership and policy direction for quality public service delivery.    </p> -->
          </div> </mat-card
        ><br />

        <mat-card class="example-card">
          <div class="heading">
            <mat-card-title>Vision</mat-card-title>
            <p>
              {{ organizationsObj[0]?.vision }}
            </p>
            <!-- <p>
              The lead agency in excellence, organization and coordination of Government business.
            </p> -->
          </div> </mat-card
        ><br />

        <!-- <mat-card class="example-card">
          <div class="heading">
            <mat-card-title>Core Values</mat-card-title>
            <p>
              {{ organizationsObj[0]?.vision }}
            </p>
            <p>
              The cabinet is guided by the following set of values:
            </p>
              <ol style="color: #000;">
                <li>Patriotism</li>
                <li>National unity</li>
                <li>Commitment to duty</li>
                <li>Teamwork</li>
                <li>Integrity and efficiency</li>
              </ol>

          </div> </mat-card
        ><br /> -->

        <mat-card class="example-card">
          <mat-card-title class="content">Social media links</mat-card-title>

          <div class="main-view" style="color: #000">
            <P
              ><span>Twitter: </span>
              <a
                style="
                  font-size: 18px;
                  font-weight: bold;
                  color: rgb(69, 69, 69);
                  padding-left: 10px;
                "
              >
                {{ socialObj[0]?.twitter_link }}</a
              ></P
            >
            <!-- <P
              ><span >Twitter: </span>
              <b><a href="https://twitter.com/StateHouseKenya" style="font-size: 18px; font-weight: bold; color: rgb(69, 69, 69); padding-left: 10px;">State House Kenya</a></b></P
            > -->
            <P
              ><span>Facebook: </span>
              <a
                style="
                  font-size: 18px;
                  font-weight: bold;
                  color: rgb(69, 69, 69);
                  padding-left: 10px;
                "
              >
                {{ socialObj[0]?.facebook_link }}</a
              >
              <!-- <b><a href="https://www.facebook.com/StateHouseKenya/" style="font-size: 18px; font-weight: bold; color: rgb(69, 69, 69); ">State House Kenya</a></b> -->
            </P>
          </div> </mat-card
        ><br />
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  <br />
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
      <div class="col">
        <div class="card radius-10 border-start border-0 border-3 border-info">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 text-secondary">Total active users</p>
                <hr class="text-success" />
                <h2 class="text-center" style="font-size: 30px">
                  {{ users.length }}
                </h2>
              </div>
              <div
                class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"
              >
                <i class="fa fa-shopping-cart"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card radius-10 border-start border-0 border-3 border-danger"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 text-secondary">Upcoming Meetings</p>
                <hr class="text-success" />
                <h2 class="text-center" style="font-size: 30px">
                  {{ meetingsObj.length }}
                </h2>
              </div>
              <div
                class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"
              >
                <i class="fa fa-dollar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card radius-10 border-start border-0 border-3 border-success"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 text-secondary">Total Tasks</p>
                <hr class="text-success" />
                <h2 class="text-center" style="font-size: 30px">
                  {{ allTasksObj.length }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card radius-10 border-start border-0 border-3 border-warning"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 text-secondary">Total Contracts</p>
                <hr class="text-success" />
                <h2 class="text-center" style="font-size: 30px">
                  {{ contractsObject.length }}
                </h2>
              </div>
              <div
                class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"
              >
                <i class="fa fa-users"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard-section">
    <mat-card class="card card-dash">
      <h2>Up coming meetings</h2>
      <hr class="btn-success" />
      <mat-divider></mat-divider><br />

      <div
        class="meeting-section desc-section heading-section"
        *ngFor="let meeting of meetingsObj"
      >
        <div class="desc-section">
          <strong>{{ meeting.meeting_title }}</strong
          ><br /><br />
          <hr />
          <p>
            Meeting address: {{ meeting.meeting_address }} <br />
            Meeting invitees:
            <b *ngFor="let item of meeting.invitees"
              >{{ item?.first_name }} {{ item?.last_name }},
            </b>
            <br />
            Meeting start date: {{ meeting?.start_date | date : "medium" }}
            <br />
          </p>
        </div>

        <div class="action pointer">
          <div class="action-icon">
            <mat-icon class="a-icon">cloud_download</mat-icon>
          </div>

          <div class="body-action" (click)="openBook(meeting)">
            <h3>Open Book</h3>
            <span>{{ meeting.start_date | date : "medium" }}</span>
          </div>
        </div>
        <div class="view-">
          <button
            mat-button
            class="example-icon view-btn"
            (click)="viewMeetingDetails(meeting)"
            aria-label="Example icon-button with share icon"
          >
            View
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
