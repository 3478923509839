<div class="wrapper" style="background-color: white">
  <div id="left">
    <div class="sign-in">
      <img
        src="../../../assets/img/Logotype-2.png"
        width="250px"
        height="200px"
        style="
          margin-top: -4px;
          margin-bottom: 10px;
          padding-bottom: 30px;
          padding-top: 30px;
        "
      />
      <mat-h5
        style="
          text-align: center;
          margin-bottom: 4rem;
          font-weight: 700;
          font-size: 30px;
          margin-top: -15px;
        "
      >
        Sign In
      </mat-h5>
      <form [formGroup]="formGroup" id="LoginForm" class="LoginForm">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label style="font-size: 2.125rem; line-height: 1.75rem">Email Address</mat-label>
          <input
            type="text"
            placeholder="Email Address"
            formControlName="username"
            matInput
          />
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label style="font-size: 2.125rem; line-height: 1.75rem">Password</mat-label>
          <input
            [type]="passwordHide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Password"
            matInput
          />
        </mat-form-field>
        <br />

        <button
          mat-stroked-button
          class="button-yellow"
          style="width: 100%; margin-top: 2rem"
          (click)="onSubmit(formGroup.value)"
        >
          <p style="margin-top: 1rem">LogIn</p>
        </button>
        <button
          mat-stroked-button
          class="button-winestone"
          style="width: 100%; margin-top: 1rem"
          (click)="forgotPassword()"
        >
          <p style="margin-top: 1rem">Forgot Password</p>
        </button>
      </form>
    </div>
  </div>
</div>
